import React, { useEffect } from "react";
import { Route, Routes, useNavigate, useLocation } from "react-router-dom";
import HomePage from "./components/HomePage";
import SignIn from "./components/SignIn";
import SignUp from "./components/SignUp";
import Dashboard from "./components/Dashboard";
import PackageList from "./components/PackageList";
import Checkout from "./components/Checkout";
import OpenTasks from "./components/OpenTasks";
import Invoices from "./components/Invoices";
import CompanyDetail from "./components/CompanyDetail";
import PackageDetail from "./components/PackageDetail";
import Activities from "./components/Activities";
import Users from "./components/Users";
import Profile from "./components/Profile";
import Confirmation from "./components/Confirmation";
import RequireAuth from "./components/RequireAuth";
import CompanyList from "./super-admin-compoenents/CompanyList";
import TimeLogs from "./super-admin-compoenents/TimeLogs";
import InvoicesList from "./super-admin-compoenents/InvoiceList";
import PaymentsList from "./super-admin-compoenents/PaymentsList";
import Tasks from "./super-admin-compoenents/Tasks";
import AllUsers from "./super-admin-compoenents/UserList";
import AllPackages from "./super-admin-compoenents/Packages";
import ForgotPassword from "./components/ForgotPassword";
import ResetPassword from "./components/ResetPassword";

const AppRoutes = () => {
  const navigate = useNavigate();
  const location = useLocation();

  useEffect(() => {
    const handleUnauthorized = () => {
      if (location.pathname !== "/signin") {
        // Check whether the user is not on the signin page
        navigate("/signin");
      }
    };

    window.addEventListener("unauthorized", handleUnauthorized);

    return () => {
      window.removeEventListener("unauthorized", handleUnauthorized);
    };
  }, [navigate, location]);

  return (
    <Routes>
      <Route path="/" element={<HomePage />} />
      <Route path="/signin" element={<SignIn />} />
      <Route path="/signup" element={<SignUp />} />
      <Route path="/forgot-password" element={<ForgotPassword />} />
      <Route path="/reset-password/:token" element={<ResetPassword />} />

      <Route
        path="/dashboard"
        element={
          <RequireAuth>
            <Dashboard />
          </RequireAuth>
        }
      />
      <Route
        path="/profile"
        element={
          <RequireAuth>
            <Profile />
          </RequireAuth>
        }
      />
      <Route
        path="/tasks"
        element={
          <RequireAuth>
            <OpenTasks />
          </RequireAuth>
        }
      />
      <Route
        path="/invoices"
        element={
          <RequireAuth requiredRole="isAdmin">
            <Invoices />
          </RequireAuth>
        }
      />
      <Route
        path="/activities"
        element={
          <RequireAuth>
            <Activities />
          </RequireAuth>
        }
      />
      <Route
        path="/checkout"
        element={
          <RequireAuth requiredRole="isAdmin">
            <Checkout />
          </RequireAuth>
        }
      />
      <Route
        path="/confirmation"
        element={
          <RequireAuth requiredRole="isAdmin">
            <Confirmation />
          </RequireAuth>
        }
      />
      <Route
        path="/users"
        element={
          <RequireAuth requiredRole="isAdmin">
            <Users />
          </RequireAuth>
        }
      />

      <Route
        path="/packages"
        element={
          <RequireAuth requiredRole="isAdmin">
            <PackageList />
          </RequireAuth>
        }
      ></Route>
      <Route
        path="/packages/:id"
        element={
          <RequireAuth>
            <PackageDetail />
          </RequireAuth>
        }
      />
      <Route
        path="/companies"
        element={
          <RequireAuth requiredRole="isSuperAdmin">
            <CompanyList />
          </RequireAuth>
        }
      />
      <Route
        path="/timelogs"
        element={
          <RequireAuth requiredRole="isSuperAdmin">
            <TimeLogs />
          </RequireAuth>
        }
      />
      <Route
        path="/allinvoices"
        element={
          <RequireAuth requiredRole="isSuperAdmin">
            <InvoicesList />
          </RequireAuth>
        }
      />
      <Route
        path="/allpayments"
        element={
          <RequireAuth requiredRole="isSuperAdmin">
            <PaymentsList />
          </RequireAuth>
        }
      />
      <Route
        path="/alltasks"
        element={
          <RequireAuth requiredRole="isSuperAdmin">
            <Tasks />
          </RequireAuth>
        }
      />
      <Route
        path="/allusers"
        element={
          <RequireAuth requiredRole="isSuperAdmin">
            <AllUsers />
          </RequireAuth>
        }
      />
      <Route
        path="/allpackages"
        element={
          <RequireAuth requiredRole="isSuperAdmin">
            <AllPackages />
          </RequireAuth>
        }
      />
    </Routes>
  );
};

export default AppRoutes;
