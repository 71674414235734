import React, { useState, useEffect } from "react";
import {
  Container,
  TextField,
  Button,
  MenuItem,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  TablePagination,
  Alert,
  AlertTitle,
  Box,
  Typography,
  CircularProgress,
} from "@mui/material";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFnsV3";
import { DateTimePicker } from "@mui/x-date-pickers/DateTimePicker";
import { Edit, Delete } from "@mui/icons-material";
import RefreshIcon from "@mui/icons-material/Refresh";
import AddIcon from "@mui/icons-material/Add";
import ClientApi from "../services/ClientApi";

function TimeLogs() {
  const [data, setData] = useState([]);
  const [companies, setCompanies] = useState([]);
  const [tasks, setTasks] = useState([]);
  const [dataLoading, setDataLoading] = useState(true);
  const [open, setOpen] = useState(false);
  const [errorMessage, setErrorMessage] = useState(null);
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
  const [deleteTimeLogId, setDeleteTimeLogId] = useState(null);
  const [form, setForm] = useState({
    id: "",
    company: "",
    timeStamp: new Date(),
    invertedHours: "",
    description: "",
  });
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(25);
  const [search, setSearch] = useState("");
  const [filter, setFilter] = useState("");
  const [refresh, setRefresh] = useState(0);

  useEffect(() => {
    // Fetch companies from your data source
    const fetchCompanies = async () => {
      const response = await ClientApi.get("/superadmin/companies"); // Replace with your API endpoint
      setCompanies(response.data);
    };

    fetchCompanies();

    // Fetch time logs from your data source
    const fetchTimeLogs = async () => {
      const response = await ClientApi.get("/superadmin/timelogs"); // Replace with your API endpoint
      setData(response.data);
    };

    fetchTimeLogs();

    const fetchTasks = async () => {
      const response = await ClientApi.get("/superadmin/allopentasks"); // Replace with your API endpoint
      setTasks(response.data);
      setDataLoading(false);
    };

    fetchTasks();
  }, [refresh]);

  const handleDeleteModalOpen = (timeLogId) => {
    setDeleteTimeLogId(timeLogId);
    setIsDeleteModalOpen(true);
  };
  const handleDeleteModalClose = (event, reason) => {
    if (reason && reason === "backdropClick") return;
    setIsDeleteModalOpen(false);
    setDeleteTimeLogId(null);
    setErrorMessage(null);
  };

  const handleClickOpen = (item) => {
    if (item) {
      setForm(item);
    } else {
      setForm({
        id: "",
        company: "",
        timeStamp: new Date(),
        invertedHours: "",
        description: "",
      });
    }
    setOpen(true);
  };

  const handleClose = (event, reason) => {
    if (reason && reason === "backdropClick") return;
    setOpen(false);
    setErrorMessage(null);
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setForm((prev) => ({
      ...prev,
      [name]: value,
    }));
  };

  const handleDateChange = (date) => {
    setForm((prev) => ({
      ...prev,
      timeStamp: date,
    }));
  };

  const handleSave = () => {
    if (form._id) {
      ClientApi.put(`/superadmin/timelogs/${form._id}`, form)
        .then((response) => {
          setData((prev) =>
            prev.map((item) => (item._id === form._id ? response.data : item))
          );
          handleClose();
        })
        .catch((err) => {
          console.log(err);
          setErrorMessage(
            err.response?.data?.message ??
              err.response?.message ??
              err.message ??
              err
          );
        });
    } else {
      // Create new item
      ClientApi.post("/superadmin/timelogs", form)
        .then((response) => {
          setData((prev) => [...prev, response.data]);
          handleClose();
        })
        .catch((err) => {
          console.log(err);
          setErrorMessage(
            err.response?.data?.message ??
              err.response?.message ??
              err.message ??
              err
          );
        });
    }
  };

  const handleDelete = () => {
    ClientApi.delete(`/superadmin/timelogs/${deleteTimeLogId}`)
      .then(() => {
        setData((prev) => prev.filter((item) => item._id !== deleteTimeLogId));
        handleDeleteModalClose();
      })
      .catch((err) => {
        console.log(err);
        setErrorMessage(
          err.response?.data?.message ??
            err.response?.message ??
            err.message ??
            err
        );
      });
  };

  const handleSearchChange = (e) => {
    setSearch(e.target.value);
  };

  const handleFilterChange = (e) => {
    setFilter(e.target.value);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const filteredData = data.filter(
    (row) =>
      (!filter || row.company === filter) &&
      (row.description.toLowerCase().includes(search.toLowerCase()) ||
        row.invertedHours.toString().includes(search) ||
        new Date(row.timeStamp).toLocaleString().includes(search))
  );

  return (
    <Container>
      <Box my={3}>
        <Typography variant="h3" align="center">
          Time Logs
        </Typography>
      </Box>
      <Box display="flex" justifyContent="flex-end">
        <IconButton
          onClick={() => handleClickOpen()}
          color="primary"
          aria-label="add"
        >
          <AddIcon />
        </IconButton>
        <IconButton
          onClick={() => {
            setDataLoading(true);
            setRefresh(refresh + 1);
          }}
          color="primary"
          aria-label="refresh"
        >
          <RefreshIcon />
        </IconButton>
      </Box>
      <TextField
        label="Search"
        value={search}
        onChange={handleSearchChange}
        fullWidth
        margin="normal"
      />
      <TextField
        select
        label="Filter by Company"
        value={filter}
        onChange={handleFilterChange}
        fullWidth
        margin="normal"
      >
        <MenuItem value="">All</MenuItem>
        {companies.map((company) => (
          <MenuItem key={company._id} value={company._id}>
            {company.companyName}
          </MenuItem>
        ))}
      </TextField>

      <TableContainer component={Paper}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell style={{ fontWeight: "bold" }}>Company</TableCell>
              <TableCell style={{ fontWeight: "bold" }}>DateTime</TableCell>
              <TableCell style={{ fontWeight: "bold" }}>Hours</TableCell>
              <TableCell style={{ fontWeight: "bold" }}>Description</TableCell>
              <TableCell style={{ fontWeight: "bold" }}>Actions</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {dataLoading && (
              <TableRow>
                <TableCell colSpan={5} align="center">
                  <CircularProgress />
                </TableCell>
              </TableRow>
            )}
            {!dataLoading &&
              filteredData
                .sort((a, b) => new Date(b.timeStamp) - new Date(a.timeStamp))
                .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                .map((row) => (
                  <TableRow key={row._id}>
                    <TableCell>
                      {
                        companies.find((c) => c._id === row.company)
                          ?.companyName
                      }
                    </TableCell>
                    <TableCell>
                      {new Date(row.timeStamp).toLocaleString()}
                    </TableCell>
                    <TableCell>{row.invertedHours}</TableCell>
                    <TableCell>{row.description}</TableCell>
                    <TableCell>
                      <IconButton onClick={() => handleClickOpen(row)}>
                        <Edit />
                      </IconButton>
                      <IconButton
                        onClick={() => handleDeleteModalOpen(row._id)}
                      >
                        <Delete />
                      </IconButton>
                    </TableCell>
                  </TableRow>
                ))}
          </TableBody>
        </Table>
      </TableContainer>
      <TablePagination
        component="div"
        count={filteredData.length}
        page={page}
        onPageChange={handleChangePage}
        rowsPerPage={rowsPerPage}
        onRowsPerPageChange={handleChangeRowsPerPage}
      />
      <Dialog open={open} onClose={handleClose}>
        <DialogTitle>{form._id ? "Edit Time Log" : "Add Time Log"}</DialogTitle>
        <DialogContent>
          <TextField
            select
            label="Company"
            name="company"
            value={form.company}
            onChange={handleChange}
            fullWidth
            margin="normal"
          >
            {companies.map((company) => (
              <MenuItem key={company._id} value={company._id}>
                {company.companyName}
              </MenuItem>
            ))}
          </TextField>
          <TextField
            select
            label="Task"
            name="task"
            value={form.task}
            onChange={handleChange}
            fullWidth
            margin="normal"
          >
            {tasks
              .filter((t) => t.company == form.company)
              .map((task) => (
                <MenuItem key={task._id} value={task._id}>
                  {task.title}
                </MenuItem>
              ))}
          </TextField>
          <LocalizationProvider dateAdapter={AdapterDateFns}>
            <DateTimePicker
              label="Date & Time"
              value={new Date(form.timeStamp)}
              onChange={handleDateChange}
              renderInput={(props) => (
                <TextField {...props} fullWidth margin="normal" />
              )}
            />
          </LocalizationProvider>

          <TextField
            label="Hours"
            name="invertedHours"
            type="number"
            value={form.invertedHours}
            onChange={handleChange}
            fullWidth
            margin="normal"
          />
          <TextField
            label="Description"
            name="description"
            multiline
            value={form.description}
            onChange={handleChange}
            fullWidth
            margin="normal"
          />
        </DialogContent>
        {errorMessage && (
          <Alert severity="error">
            <AlertTitle>Error</AlertTitle>
            {errorMessage}
          </Alert>
        )}
        <DialogActions>
          <Button onClick={handleClose} color="secondary">
            Cancel
          </Button>
          <Button onClick={handleSave} color="primary">
            Save
          </Button>
        </DialogActions>
      </Dialog>
      <Dialog open={isDeleteModalOpen} onClose={handleDeleteModalClose}>
        <DialogTitle>Are you sure you want to DELETE this TimeLog?</DialogTitle>
        {errorMessage && (
          <Alert severity="error">
            <AlertTitle>Error</AlertTitle>
            {errorMessage}
          </Alert>
        )}
        <DialogActions>
          <Button onClick={handleDeleteModalClose}>Cancel</Button>
          <Button color="error" onClick={handleDelete}>
            Delete
          </Button>
        </DialogActions>
      </Dialog>
    </Container>
  );
}

export default TimeLogs;
