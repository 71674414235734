import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import Auth from "../services/Auth";

const RequireAuth = ({ children, requiredRole }) => {
  const navigate = useNavigate();
  const [isAuthorized, setIsAuthorized] = useState(false);

  useEffect(() => {
    const checkAuthentication = () => {
      if (!Auth.isAuthenticated()) {
        navigate("/signin", { replace: true });
        return false;
      }
      let allowed = true;

      if (requiredRole === "isAdmin") {
        allowed = Auth.isAdmin();
      } else if (requiredRole === "isSuperAdmin") {
        allowed = Auth.isSuperAdmin();
      } else if (requiredRole) {
        allowed = Auth.hasRole(requiredRole);
      }
      if (!allowed) navigate("/dashboard", { replace: true });
      return allowed;
    };

    const authorized = checkAuthentication();
    setIsAuthorized(authorized);
  }, [navigate, requiredRole]);

  if (!isAuthorized) {
    // You can also return a loader or null if you want a loading state
    return null;
  }

  return <>{children}</>;
};

export default RequireAuth;
