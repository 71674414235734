import React, { useState, useEffect } from "react";
import {
  Container,
  TextField,
  Button,
  MenuItem,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  TablePagination,
  Alert,
  AlertTitle,
  Box,
  Typography,
  FormControlLabel,
  InputAdornment,
} from "@mui/material";
// import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from "@mui/material/Checkbox";
import { Edit, Delete } from "@mui/icons-material";
import RefreshIcon from "@mui/icons-material/Refresh";
import AddIcon from "@mui/icons-material/Add";
import EditIcon from "@mui/icons-material/Edit";
import MailLockIcon from "@mui/icons-material/MailLock";
import VerifiedUserIcon from "@mui/icons-material/VerifiedUser";
import ClientApi from "../services/ClientApi";

function AllUsers() {
  const emptyForm = {
    firstName: "",
    lastName: "",
    emailAddress: "",
    company: "",
    approved: true,
    role: "admin",
  };
  const [data, setData] = useState([]);
  const [companies, setCompanies] = useState([]);
  const [open, setOpen] = useState(false);
  const [errorMessage, setErrorMessage] = useState(null);
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
  const [deleteId, setDeleteId] = useState(null);
  const [form, setForm] = useState(emptyForm);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(25);
  const [search, setSearch] = useState("");
  const [filter, setFilter] = useState("");
  const [openConfirmDialog, setOpenConfirmDialog] = useState(false);
  const [emailType, setEmailType] = useState(null);
  const [selectedUser, setSelectedUser] = useState(null);
  const [refresh, setRefresh] = useState(0);
  const [isEditable, setIsEditable] = useState(false);

  useEffect(() => {
    // Fetch companies from your data source
    const fetchCompanies = async () => {
      const response = await ClientApi.get("/superadmin/companies"); // Replace with your API endpoint
      setCompanies(response.data);
    };

    fetchCompanies();

    // Fetch time logs from your data source
    const fetchUsers = async () => {
      const response = await ClientApi.get("/superadmin/users"); // Replace with your API endpoint
      setData(response.data);
    };

    fetchUsers();
  }, [refresh]);

  const toggleEditable = () => {
    setIsEditable(!isEditable);
  };

  const handleOpenConfirmDialog = (user, type) => {
    setSelectedUser(user);
    setEmailType(type);
    setOpenConfirmDialog(true);
  };

  const handleCloseConfirmDialog = () => {
    setOpenConfirmDialog(false);
    setSelectedUser(null);
    setEmailType(null);
  };

  const handleConfirmSendEmail = async () => {
    if (emailType === "activate") {
      await ClientApi.put(`/superadmin/users/activate/${selectedUser._id}`);
    } else if (emailType === "setPassword") {
      await ClientApi.put(
        `/superadmin/users/sendsetpassword/${selectedUser._id}`
      );
    } else return;
    console.log(`Sending ${emailType} email to ${selectedUser.name}`);
    setOpenConfirmDialog(false);
  };

  const handleDeleteModalOpen = (taskId) => {
    setDeleteId(taskId);
    setIsDeleteModalOpen(true);
  };
  const handleDeleteModalClose = (event, reason) => {
    if (reason && reason === "backdropClick") return;
    setIsDeleteModalOpen(false);
    setDeleteId(null);
    setErrorMessage(null);
  };

  const handleClickOpen = (item) => {
    if (item) {
      setForm(item);
    } else {
      setForm(emptyForm);
    }
    setOpen(true);
  };

  const handleClose = (event, reason) => {
    if (reason && reason === "backdropClick") return;
    setOpen(false);
    setErrorMessage(null);
    setIsEditable(false);
  };

  const handleChange = (e) => {
    const { name, value, type, checked } = e.target;
    setForm((prev) => ({
      ...prev,
      [name]: type === "checkbox" ? checked : value,
    }));
  };

  const handleSave = () => {
    if (form._id) {
      ClientApi.put(`/superadmin/users/${form._id}`, form)
        .then((response) => {
          setData((prev) =>
            prev.map((item) => (item._id === form._id ? response.data : item))
          );
          handleClose();
        })
        .catch((err) => {
          console.log(err);
          setErrorMessage(
            err.response?.data?.message ??
              err.response?.message ??
              err.message ??
              err
          );
        });
    } else {
      // Create new item
      ClientApi.post("/superadmin/users", form)
        .then((response) => {
          setData((prev) => [...prev, response.data]);
          handleClose();
        })
        .catch((err) => {
          console.log(err);
          setErrorMessage(
            err.response?.data?.message ??
              err.response?.message ??
              err.message ??
              err
          );
        });
    }
    setIsEditable(false);
  };

  const handleDelete = () => {
    ClientApi.delete(`/superadmin/users/${deleteId}`)
      .then(() => {
        setData((prev) => prev.filter((item) => item._id !== deleteId));
        handleDeleteModalClose();
      })
      .catch((err) => {
        console.log(err);
        setErrorMessage(
          err.response?.data?.message ??
            err.response?.message ??
            err.message ??
            err
        );
      });
  };

  const handleSearchChange = (e) => {
    setSearch(e.target.value);
  };

  const handleFilterChange = (e) => {
    setFilter(e.target.value);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const filteredData = data.filter(
    (row) =>
      (!filter || row.company === filter) &&
      (row.firstName.toLowerCase().includes(search.toLowerCase()) ||
        row.lastName.toLowerCase().includes(search.toLowerCase()) ||
        row.emailAddress.toLowerCase().includes(search.toLowerCase()))
  );

  return (
    <Container>
      <Box my={3}>
        <Typography variant="h3" align="center">
          Users
        </Typography>
      </Box>
      <Box display="flex" justifyContent="flex-end">
        <IconButton
          onClick={() => handleClickOpen()}
          color="primary"
          aria-label="add"
        >
          <AddIcon />
        </IconButton>
        <IconButton
          onClick={() => setRefresh(refresh + 1)}
          color="primary"
          aria-label="refresh"
        >
          <RefreshIcon />
        </IconButton>
      </Box>
      <TextField
        label="Search"
        value={search}
        onChange={handleSearchChange}
        fullWidth
        margin="normal"
      />
      <TextField
        select
        label="Filter by Company"
        value={filter}
        onChange={handleFilterChange}
        fullWidth
        margin="normal"
      >
        <MenuItem value="">All</MenuItem>
        {companies.map((company) => (
          <MenuItem key={company._id} value={company._id}>
            {company.companyName}
          </MenuItem>
        ))}
      </TextField>

      <TableContainer component={Paper}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell style={{ fontWeight: "bold" }}>First Name</TableCell>
              <TableCell style={{ fontWeight: "bold" }}>Last Name</TableCell>
              <TableCell style={{ fontWeight: "bold" }}>Email</TableCell>
              <TableCell style={{ fontWeight: "bold" }}>Company</TableCell>
              <TableCell style={{ fontWeight: "bold" }}>Role</TableCell>
              <TableCell style={{ fontWeight: "bold" }}>Approved</TableCell>
              <TableCell style={{ fontWeight: "bold" }}>Actions</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {filteredData
              .sort((a, b) => new Date(b.createdAt) - new Date(a.createdAt))
              .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
              .map((row) => (
                <TableRow key={row._id}>
                  <TableCell>{row.firstName}</TableCell>
                  <TableCell>{row.lastName}</TableCell>
                  <TableCell>{row.emailAddress}</TableCell>
                  <TableCell>
                    {companies.find((c) => c._id === row.company)?.companyName}
                  </TableCell>
                  <TableCell>{row.role}</TableCell>
                  <TableCell>{row.approved ? "Yes" : "No"}</TableCell>
                  <TableCell>
                    <IconButton onClick={() => handleClickOpen(row)}>
                      <Edit />
                    </IconButton>
                    <IconButton onClick={() => handleDeleteModalOpen(row._id)}>
                      <Delete />
                    </IconButton>
                    <IconButton
                      onClick={() => handleOpenConfirmDialog(row, "activate")}
                    >
                      <VerifiedUserIcon />
                    </IconButton>
                    <IconButton
                      onClick={() =>
                        handleOpenConfirmDialog(row, "setPassword")
                      }
                    >
                      <MailLockIcon />
                    </IconButton>
                  </TableCell>
                </TableRow>
              ))}
          </TableBody>
        </Table>
      </TableContainer>
      <TablePagination
        component="div"
        count={filteredData.length}
        page={page}
        onPageChange={handleChangePage}
        rowsPerPage={rowsPerPage}
        onRowsPerPageChange={handleChangeRowsPerPage}
      />
      <Dialog open={open} onClose={handleClose}>
        <DialogTitle>{form._id ? "Edit User" : "Add User"}</DialogTitle>
        <DialogContent>
          <TextField
            label="ID"
            name="_id"
            value={form._id}
            onChange={handleChange}
            fullWidth
            disabled={true}
            margin="normal"
          />
          <TextField
            select
            label="Company"
            name="company"
            value={form.company}
            onChange={handleChange}
            fullWidth
            margin="normal"
            disabled={!isEditable && !!form._id}
            InputProps={{
              endAdornment: !isEditable && !!form._id && (
                <InputAdornment position="end">
                  <IconButton onClick={toggleEditable} disabled={isEditable}>
                    <EditIcon />
                  </IconButton>
                </InputAdornment>
              ),
            }}
          >
            {companies.map((company) => (
              <MenuItem key={company._id} value={company._id}>
                {company.companyName}
              </MenuItem>
            ))}
          </TextField>
          <TextField
            label="First Name"
            name="firstName"
            value={form.firstName}
            onChange={handleChange}
            fullWidth
            margin="normal"
          />
          <TextField
            label="Last Name"
            name="lastName"
            value={form.lastName}
            onChange={handleChange}
            fullWidth
            margin="normal"
          />
          <TextField
            label="Email"
            name="emailAddress"
            value={form.emailAddress}
            onChange={handleChange}
            fullWidth
            margin="normal"
          />
          <TextField
            label="Role"
            name="role"
            value={form.role}
            onChange={handleChange}
            fullWidth
            margin="normal"
          />
          <FormControlLabel
            control={
              <Checkbox
                name="approved"
                checked={form.approved}
                onChange={handleChange}
                fullWidth
                margin="normal"
              />
            }
            label="Approved"
          />
        </DialogContent>
        {errorMessage && (
          <Alert severity="error">
            <AlertTitle>Error</AlertTitle>
            {errorMessage}
          </Alert>
        )}
        <DialogActions>
          <Button onClick={handleClose} variant="contained" color="secondary">
            Cancel
          </Button>
          <Button onClick={handleSave} variant="contained" color="primary">
            Save
          </Button>
        </DialogActions>
      </Dialog>
      <Dialog open={isDeleteModalOpen} onClose={handleDeleteModalClose}>
        <DialogTitle>Are you sure you want to DELETE this User?</DialogTitle>
        {errorMessage && (
          <Alert severity="error">
            <AlertTitle>Error</AlertTitle>
            {errorMessage}
          </Alert>
        )}
        <DialogActions>
          <Button
            variant="contained"
            color="secondary"
            onClick={handleDeleteModalClose}
          >
            Cancel
          </Button>
          <Button variant="contained" color="error" onClick={handleDelete}>
            Delete
          </Button>
        </DialogActions>
      </Dialog>
      <Dialog open={openConfirmDialog} onClose={handleCloseConfirmDialog}>
        <DialogTitle>{"Confirm Activate and Send Email"}</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Are you sure you want to activate and send a {emailType} email to{" "}
            {selectedUser?.name}?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseConfirmDialog} color="primary">
            Cancel
          </Button>
          <Button onClick={handleConfirmSendEmail} color="secondary">
            Confirm
          </Button>
        </DialogActions>
      </Dialog>
    </Container>
  );
}

export default AllUsers;
